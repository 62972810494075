import { indexWx } from '@/api/index.js';
import localStore from 'storejs'; // localStorage的二次封装(可直接存储与获取对象)

export default {
  data() {
  	return {

  	}
  },
  created() {
  	
  },
  methods: {
    getRegisterData(unionid, l) {
      let params = { 
        l,
        unionid,
        browserType: 2, // 浏览器类型: 1-微信小程序, 2-浏览器
      };
      indexWx(params).then(res => {
        console.log('index/wx: ',res);
        
        // 登记类型: 1-预登记, 2-现场登记
        localStore.set('flowType', res.data.preRegister || 1);
        
        let flowNode = res.data.flowNode;
        if (flowNode) {
          localStore.set('flowId', flowNode.flowId);
          
          // 存储中文页面样式
          localStore.set('css', flowNode.css || {});
          
          // 存储英文页面样式(预防存在中英文切换)
          localStore.set('cssEn', flowNode.cssEn || {});
          
          // 页面替换: 根据返回的defaultLang跳转到指定的中(英)文流程页面
          // defaultLang: 1-中文, 2-英文
          this.$router.replace({
            // path: flowNode.defaultLang == 1 ? flowNode.h5Url : flowNode.h5UrlEn,
            path: flowNode.h5Url,
            query: {
              needPay: res.data.needPay || 0 // 1-需要支付，0-不需要支付
            }
          });
        } else {
          // 跳转到错误提示页面
          this.$router.replace({
            path: '/errorTips',
            query: {
              errorMessage: '校验失败 <br> verification failed'
            }
          })
        }
      }).catch(err => {
        // 跳转到错误提示页面
        this.$router.replace({
          path: '/errorTips',
          query: {
            errCode: err.code,
            errorMessage: err.message
          }
        })
      })
    },
  },
}