<!-- 授权页面：请求微信授权信息 -->
<template>
	<div class="page">
		<van-loading size="30px" type="spinner" :color="color" vertical>
			<span class="font14" :style="{color: color}">Loading...</span>
		</van-loading>
	</div>
</template>

<script>
	import { Loading } from 'vant';
	import { voucherState } from '@/api/index.js';
	import localStore from 'storejs'; // localStorage的二次封装(可直接存储与获取对象)
  import indexWxMixin from '@/view/process/mixin/index_wx.js';

	export default {
		mixins: [indexWxMixin],
		components: {
			[Loading.name]: Loading
		},
		data() {
			return {
				color: '#969799',
        isCamp: false,
        isCampNormal: false,        
			}
		},
		created() {
			
			// flowType登记类型: 1-预登记, 2-现场登记
			// lang语言: zh-中文, en-英文
      // flowId: 流程id
			let { flowType = 1, lang = 'en', unionid = '', l = '', flowId } = localStore();
      this.isCamp = flowId == 902 || flowId == 8102;
      this.isCampNormal = flowId == 8102;
      this.setPageTitle(flowType); // 设置页面标题
      
			if (unionid) {
			
				this.unionid = unionid;
				this.l = l;
				this.lang = lang;
				this.flowId = flowId;
			
				this.validateSignUp(); // 校验是否已报名成功
				
			} else {
				// 跳转到错误提示页面
				// this.$router.replace({
				// 	path: '/errorTips',
				// 	query: {
				// 		errorMessage: '访问失败, 参数缺失 <br > Access failed, parameter missing'
				// 	}
				// })
        
        // 重定向页面
        this.getRegisterData(unionid, l);
			}
		},
		methods: {
			// 设置页面标题
			setPageTitle(flowType) {
				// 语言配置this.lang因先后顺序问题, 所以需要setTimeout延时取最后的配置
        setTimeout(() => {
          // 户外展海外H5，902（VIP）,8102（普通）
          if (flowType == 1) { // 预先登记
            let tit = this.isCamp ? '动力大湾生活节预先登记' : '深圳时尚家居设计周预先登记';
            document.title = this.lang == 'en' ? 'SZCW Pre-registration' : tit;
          } else { // 现场登记、vip登记
            let tit = this.isCamp ? '动力大湾生活节入场登记' : '深圳时尚家居设计周入场登记';
            document.title = this.lang == 'en' ? 'SZCW Registration' : tit;
          }
        })
			},
			// 校验是否已报名成功
			validateSignUp() {
				voucherState().then(res => {
					if (!res.data.state || res.data.state == 0) { // 未报名成功时
						this.$router.replace({
							path: '/errorTips',
							query: {
								errorMessage: this.lang = 'en' ? 'Missing parameter, <br> unable to access membership card' : '缺失参数, 不能访问会籍卡！'
							}
						})
					} else {
						if (res.data.state == 1) {  // 报名成功时: 进入会籍卡页面
							this.$router.replace({
								path: this.lang === 'en' ? '/memberCode_en' : '/memberCode',
								query: {
									u: 'sms' // 标注来源: 通过短信链接访问
								}
							})
						} else if (res.data.state == 2) { // 处于审核状态
							if (res.data.id_card_check_state == 0) { // 待审: 进入待审页面
								this.$router.replace({
									path: this.lang === 'en' ? '/waiting_en' : '/waiting',
									query: {
										u: 'sms' // 标注来源: 通过短信链接访问
									}
								})
							} else if (res.data.id_card_check_state == 2) { // 审核不通过: 进入实名校验页面
								this.$router.replace({
									path: this.lang === 'en' ? '/realnameAuth_en' : '/realnameAuth',
									query: {
										u: 'sms' // 标注来源: 通过短信链接访问
									}
								})
							}
						}
					}
				}).catch(err => {
          this.$router.replace({
          	path: '/errorTips',
          	query: {
          		errCode: err.code,
          		errorMessage: err.message
          	}
          })
        })
			},
		},
	}
</script>

<style scoped>
	.page {
		justify-content: center;
		align-items: center;
	}
</style>
